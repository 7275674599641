html {
  font-size: 125%;
  font-family: $font-primary;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  @include until($tablet) {
    font-size: 120%;
  }
}

body {
  font: normal 1rem/1.5 $font-primary;
  color: $color-body-text;
}

a {
  color: $light-teal;
  text-decoration: none;
  outline: 0;

  transition: color 0.2s ease-in, border-color 0.2s ease-in, background-color 0.2s ease-in;

  &:hover {
    color: $dark-green;
  }

  &:focus {
    outline: 0;
  }
}

h3 {
  font: normal 25px/1.5 $font-brand-secondary;

  @include rem(font-size, 25px);
}

.section-title {
  @extend %uc;

  position: relative;
  font: 400 18px/1 $font-brand;
  text-align: center;
  margin: 15px 0 50px;

  @include rem(font-size, 18px);

  @include until($phablet) {
    margin: 30px 0;
  }

  &:before {
    background-color: $black;
    display: block;
    content: "";
    width: 33px;
    height: 2px;
    margin-top: -11px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &--white {
    color: $white;

    &:before {
      background-color: $white;
      display: block;
      content: "";
      width: 33px;
      height: 2px;
      margin-top: -11px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__main-headline {
    font: 400 18px/1 $font-brand;
    position: relative;
    text-align: center;
    margin: 25px 0 75px;
    font-size: 37px;
    text-transform: lowercase;
  }
}

.news-titles {
  font: normal 25px/1.4 $font-brand-secondary;

  @include rem(font-size, 25px);
}

address {
  font-style: normal;
}

.main-listing-titles {
  font: normal 30px/1.2 $font-headline;

  @include rem(font-size, 30px);
}

.nav-section-title {
  font: normal 35px/1.2 $font-headline;

  @include rem(font-size, 35px);
}
