.related-services {
	text-align: center;

	.grid-row {
		margin-top: $base-spacing;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			display: block;
			left: 50%;
			top: 0;
			height: 100%;
			width: 1px;
			background-color: $border-related-results;

			@include until($phablet) {
				display: none;
			}
		}

		@include until($phablet) {
			margin-top: 0;
		}
	}

	h2 {
		color: $nav-link-hover;
		font: 24px/1.1 $font-headline;
		margin: 0 0 0;

		@include until($phablet) {
			margin-top: 30px;
		}
	}
}

.related-services-list {
	> li {
		margin-top: 20px;
	}
}

.related-services-link {
	@extend %uc;

	display: inline-block;
	font-style: italic;
	color: $black;
	font: normal 13px/1 $font-brand;

	&:hover,
	&.is-active {
		color: $nav-link-hover;
	}
}

//old custom styles for related services on service detail page

// .related-services-link {
// 	@extend %uc;

// 	display: inline-block;
// 	font-style: italic;
// 	color: $color-brand-blue;
// 	font: 700 13px/1 "avenir", "avenirblack", Helvetica, Arial, sans-serif;

// 	&:hover,
// 	&.is-active {
// 		color: $color-accent-green;
// 	}

// 	.custom-icon {
// 		display: inline-block;
// 		width: 10px;
// 		margin-right: 5px;
// 		font-style: normal;

// 		&:before {
// 			content: '+';
// 		}
// 	}

// 	&.is-active {
// 		.custom-icon {
// 			&:before {
// 				content: '\2013';
// 			}
// 		}
// 	}
// }
