.person-snapshot {
	background-color: #122632;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
	position: relative;
	overflow: hidden;
	padding: 90px 0 60px;

	.arrow {
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 53px;
		text-align: center;
		overflow: hidden;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 50%;
			height: 26px;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: $white;
			margin-left: -27px;
		}

		&:after {
			left: auto;
			right: 0;

			margin-left: 0px;
			margin-right: -27px;
		}
	}

	.down-arrow {
		display: inline-block;
		width: 54px;
		height: 54px;
		position: relative;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			bottom: 0;
			border-bottom: 27px solid $white;
		}

		&:before {
			border-right: 28px solid transparent;
			left: 0;
		}

		&:after {
			border-left: 28px solid transparent;
			right: 0;
		}

		@include until($phablet) {
			width: 54px;
			height: 54px;

			&:before,
			&:after {
				border-bottom-width: 27px;
			}

			&:before {
				border-right-width: 28px;
				left: 0;
			}

			&:after {
				border-left-width: 28px;
			}
		}
	}
}

.grid-col-40 {
	padding-right: 3%;

	@include until($phone-wide) {
		float: left;
		width: 30%;
		margin-right: 10px;
	}
}
.person-snapshot-img {
	max-width: 100%;
}

.person-snapshot-details {
	@include rem(font-size, 18px);

	color: $color-accent-grey;
	line-height: 1.4;

	@include until($phone-wide) {
		float: none;
		width: auto;
	}

	a {
		color: $color-accent-grey;

		&:hover {
			color: $light-teal;
		}
	}

	h1 {
		@extend .main-listing-titles;

		@include rem(font-size, 60px);

		line-height: 1.1;
		margin: 0 0 15px;

		@include until($phone-wide) {
			@include rem(font-size, 38px);
		}
	}
}

.person-snapshot-offices {
	margin-top: 5px;
}

.person-snapshot-office {
	&:after {
		content: ",";
	}

	&:last-child {
		&:after {
			content: "";
		}
	}
}

.person-snapshot-social-options {
	margin: 15px 0;

	@include until($phone-wide) {
		padding-top: 20px;
		clear: both;
	}

	.person-snapshot-social-icon {
		color: $white;

		&:hover {
			color: $white;
		}
	}
}

.person-snapshot-social-icon {
	background-color: transparent;
	font-size: 40px;
	margin-right: 5px;
	color: $white;

	position: relative;
	opacity: 1;
	transition: opacity 0.2s ease-in;

	&:before {
		position: relative;
		z-index: 5;
	}

	&:after {
		position: absolute;
		content: "";
		width: 90%;
		height: 90%;
		left: 5%;
		top: 5%;
		background-color: $black;
		display: block;
		border-radius: 25px;
	}

	&:hover {
		opacity: 0.8;
	}
}

.person-snapshot-email {
	&:before {
		background-color: $color-accent-grey;
		display: block;
		content: "";
		width: 50px;
		height: 1px;
		margin-top: -15px;
		opacity: 0.7;
		position: absolute;
	}
}

.person-snapshot-title {
	@include rem(font-size, 26px);
}

.person-snapshot-numbers {
	margin-top: 15px;

	&:before {
		background-color: $color-accent-grey;
		display: block;
		content: "";
		width: 50px;
		height: 1px;
		opacity: 0.7;
		position: absolute;
	}
}
.person-snapshot-phone {
	margin-top: 15px;

	> em {
		display: inline-block;
		min-width: 65px;
	}

	@include until($phone-wide) {
		float: left;
	}
}
