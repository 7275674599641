.page-container {
	width: 100%;
	max-width: 1150px;
	margin: 0 auto;

	@media (max-width: 767px) {
		padding: 0 10px;
	}
}

section {
	background-color: $white;
	position: relative;
	z-index: 2;
}
