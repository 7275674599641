$white: #fff;
$black: #000;

$orange: #f7a11a;
$light-gray: #91a4b1;
$light-semi-gray: #dcdcdc;
$med-gray: #f2f2f2;
$off-white: #eae7e4;
$services-label: #332f2a;

$dark-opacity: rgba(0, 0, 0, 0.3);

$light-teal: #609b90;
$dark-green: #415a68;

$nav-link-hover: $light-teal;

$bg-light-green: $light-teal;
$bg-dark-green: $dark-green;
$bg-light-gray: $med-gray;

$box-shadow-color: $dark-opacity;

$header-border: $light-semi-gray;
$main-nav-color: $dark-green;

//Secondary Font
$secondary-font: georgia, serif;
$headline-font: "bebas-neue", georgia, serif;
$tertiary-font: "avenir", "AvenirLTW04-95Black", georgia, sans-serif;
