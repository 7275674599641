.home-locations {
	padding: 80px 0;
	background-color: $bg-home-locations;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: $color-brand-white;
}

.content-section-offices {
	max-width: none !important;
}

.home-locations-title {
	@extend %uc;

	font-size: 1em;
	font-family: $font-brand-secondary;
	margin: 0 0 20px;
}

.home-locations-list {
	@include rem(font-size, 16px);

	@include until($tablet) {
		@include rem(font-size, 16px);
	}

	@include until($phone-wide) {
		width: 50%;
		float: left;
	}

	> li {
		margin-top: 8px;
	}

	a {
		color: $color-brand-white;

		&:hover,
		&.active {
			color: $nav-link-hover;
		}
	}

	.active {
		&:after {
			content: "";
			display: inline-block;

			margin-left: 5px;
			width: 0;
			height: 0;
			border-left: 8px solid $white;
			border-bottom: 5px solid transparent;
			border-top: 5px solid transparent;
		}
	}
}

.home-locations-list-wrap {
	@include until($tablet) {
		width: 40%;
	}

	@include until($phone-wide) {
		width: 100%;
	}
}

.home-locations-office-wrap {
	color: $black;

	margin-top: 4px;
	padding-left: 4%;
	height: 255px;

	@include until($tablet) {
		width: 60%;
		height: auto;
	}

	@include until($phone-wide) {
		padding-left: 0;
		width: 100%;
		margin-top: 50px;
	}
}

.home-location-office-info {
	position: relative;
	overflow: hidden;
	height: 100%;
}

.home-locations-office-thumbnail {
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;

	@include until($tablet) {
		width: 100%;
		position: static;
	}

	@include until($phone-wide) {
		display: none;
	}

	> img {
		display: block;
		width: 100%;
	}
}

.home-locations-office-address {
	min-width: 240px;
	padding: 20px 25px;
	position: relative;
	z-index: 1;
	background-color: $color-accent-grey;
	float: right;
	height: 100%;

	@include until($tablet) {
		width: 100%;
	}

	address {
		@include rem(font-size, 14px);

		font-style: normal;
		line-height: 1.5;
		margin-top: 10px;

		@include until($phablet) {
			@include rem(font-size, 16px);
		}
	}

	.btn {
		position: absolute;
		bottom: 20px;

		@include until($tablet) {
			position: static;
			bottom: auto;
			margin-top: 20px;
		}
	}
}

.home-locations-office-name {
	display: block;
	font: normal 24px/1.3 $font-headline;
	color: $black;
	transition: color 0.2s ease-in;

	@include rem(font-size, 24px);

	> h3 {
		margin: 0;
		font-weight: normal;
	}

	&:hover {
		color: rgba($black, 0.75);
	}
}

.home-locations-office-map {
	display: none;
	text-align: right;

	.btn {
		margin: 0;

		.fa {
			transform: rotate(-45deg);
		}
	}
}

.office__title {
	font-family: "AvenirLTW04-95Black", georgia, sans-serif;
	font-weight: normal;
	font-size: 22px;
	text-transform: uppercase;
	margin: 15px 0 10px 0;
	color: $light-teal;
}
