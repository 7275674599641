.global-site-nav {
	.logo-main {
		display: inline-block;
		margin-top: 8px;
		width: 99px;
		height: 40px;
		position: relative;
		overflow: hidden;
		border-right: solid 2px white;
		border-left: solid 2px white;
		transition: border 0.2s ease-in-out;

		& > img {
			width: 100%;
			display: block;
		}

		&:hover {
			transition: border 0.2s ease-in-out;
			border-right: solid 2px $orange; //$light-teal;
			border-left: solid 2px $orange;
			//			& > img {
			//				transform: translateY(-40px);
			//			}
		}
	}

	//homepage custom site nav styles
	&.global-site-nav-home {
		box-shadow: 0px -2px 2px 0px $box-shadow-color;

		position: absolute;
		top: 400px;
		width: 100%;

		@include until($tablet-nav) {
			position: fixed;
			top: 0;
			transition: max-height 0.4s ease-in-out, top 0.3s ease-in-out;
		}

		.js-global-search-active & {
			top: 400px;

			@include until($tablet-nav) {
				top: 60px;
			}
		}

		&.is-fixed {
			position: fixed;
			top: 0;
			transform: translateY(0);
			transition: transform 0.3s ease-in-out;

			.js-global-search-active & {
				transform: translateY(90px);
			}

			@include until($tablet-nav) {
				position: absolute;
			}
		}

		.logo-main {
			opacity: 0;
			transition: opacity 0.3s ease;

			@include until($tablet-nav) {
				opacity: 1;
				margin-top: 2px;
			}
		}
	}

	&.is-animating {
		.logo-main {
			opacity: 1;
		}
	}
}

.global-site-nav {
	background-color: $black;
	width: 100%;
	height: 65px;
	padding-top: 4px;
	text-align: center;
	position: fixed;
	top: 0;
	z-index: 100;

	// Solves repaint issues
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;

	transition: top 0.3s ease-in-out;

	.homepage & {
		transition: none;
	}

	.js-global-search-active & {
		top: 90px;

		@include until($tablet-nav) {
			top: 60px;
		}
	}

	@include until($tablet-nav) {
		position: fixed;
		width: 100%;
		top: 0;
		text-align: left;
		height: auto;
		padding-top: 60px;
		max-height: 60px;
		overflow: hidden;

		transition: max-height 0.4s ease-in-out, top 0.3s ease-in-out;

		.logo-main {
			opacity: 1;
			position: absolute;
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
			margin-top: 0;

			> img {
				width: 100px;
			}
		}
	}

	@media all and (max-height: 450px) {
		& {
			position: absolute;
		}
	}

	&.is-active-mobilenav {
		max-height: 600px;
	}

	.nav-mobile-toggle {
		@extend %uc;

		width: 62px;
		height: 60px;
		text-align: center;
		position: absolute;
		top: 0px;
		left: 0px;
		padding-top: 40px;
		font: 400 11px/1 $font-brand;
		color: $white;

		> span,
		> span:before,
		> span:after {
			content: "";
			display: block;
			position: absolute;
			background-color: $white;
			height: 3px;
			width: 23px;
			margin: 0 auto;

			transition: all 0.2s ease-in-out;
		}

		> span {
			left: 19px;
			top: 20px;
		}

		> span:before {
			top: -7px;
		}
		> span:after {
			bottom: -7px;
		}

		&.is-active {
			background-color: $black;
			color: $color-brand-white;

			> span:before,
			> span:after {
				background-color: $color-brand-white;
			}
		}

		&.is-active span {
			&:before,
			&:after {
				top: 0;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}
		display: none;
		@include until($tablet-nav) {
			display: block !important;
		}
	}

	.page-wrap {
		@include until($tablet-nav) {
			background-color: $black;
			padding-top: 30px;
			padding-bottom: 20px;
		}
	}
}

.global-nav {
	margin-top: 10px;

	@include until($large-screen) {
		margin-top: 10px;
	}

	@include until($tablet-nav) {
		float: none;
		margin-top: 0;
	}
}
.global-nav-link {
	@extend %uc;

	font: 300 11px/1 $font-brand-secondary;

	@include rem(font-size, 11px);

	color: $white;
	display: inline-block;
	border-top: 1px solid $black;
	padding-top: 6px;
	margin-right: 20px;
	transition: all 0.3s ease-in-out;
	letter-spacing: 1px;
	@include until($large-screen) {
		margin-right: 10px;
	}

	@include until($tablet-nav) {
		border-top: 0 none;
		color: $white;
		padding-bottom: 20px;

		@include rem(font-size, 22px);
	}

	.global-nav-main & {
		@include rem(font-size, 14px);

		margin-right: 28px;

		@include until($large-screen) {
			a {
				margin-right: 18px;
			}
		}

		@include until($tablet-nav) {
			@include rem(font-size, 34px);

			margin-right: 0;
			color: $color-brand-white;
			padding-bottom: 0px;

			&:after {
				content: "";
				display: block;
				width: 43px;
				height: 1px;
				background-color: $opaquewhite;
				margin: 25px auto;
			}
		}
	}

	&:last-child {
		margin-right: 0;
	}
	&.active {
		border-top: 3px solid $white;
		color: $white;
	}
	&:hover {
		color: #ababab; //$nav-link-hover;
		border-top: 3px solid $orange;
	}

	@include until($tablet-nav) {
		margin-top: 0;
		display: block;
		text-align: center;
	}
}
@media screen and (max-width: 839px) {
	.global-nav-link:hover {
		border-top: 0px solid #000;
		color: #f7a11a;
	}
}
@media screen and (max-width: 839px) {
	.global-nav-link.active {
		border-top: 0px solid #000;
		text-decoration: overline;
	}
}

.js-global-search {
	font-size: 11px;
	font-weight: bold;
	transition: color 0.3s ease-in-out;
	color: $white;
	min-width: 14px;
	height: 17px;

	&:hover {
		color: $orange;
	}

	.js-global-search-active & {
		opacity: 1;
		color: $white;
	}

	.fa {
		font-size: 15px;

		.js-global-search-active &:before {
			content: "\f00d";
		}
	}

	&.mobile-only {
		width: 62px;
		height: 60px;
		padding-top: 15px;
		text-align: center;
		position: absolute;
		top: 0px;
		right: 0px;
		display: none;
		@include until($tablet-nav) {
			display: block !important;
		}

		.fa {
			font-size: 25px;
		}
	}
}
