.select-dropdown {
	position: relative;

	select {
		font-size: 15px;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		opacity: 0;
		z-index: 5;
		cursor: pointer;
	}

	.fa {
		position: absolute;
		right: 15px;
		font-size: 12px;
		top: 35%;
		transition: color 0.2s ease-in;
	}

	&:hover {
		.select-label {
			border-color: darken($border-inputs, 10%);
		}

		.fa {
			color: $nav-link-hover;
		}
	}
}

.select-label {
	@extend %uc;

	display: inline-block;
	background-color: $color-brand-white;
	border: 1px solid $border-inputs;
	width: 100%;
	font: normal 13px/1.4 $font-brand;
	padding: 10px 15px;

	text-align: left;
	position: relative;
	transition: border-color 0.2s ease-in;
}
