.people-quotes {
	@extend %clearChildren;

	padding-top: 30px;

	$photo-thumbnail-width: 250px;

	@include until($tablet-alt) {
		border-bottom: 1px solid $border-boxes-arrows;
	}

	.boxes-arrows-image {
		&:after {
			content: "View Bio";
		}
	}

	.boxes-arrows-title {
		font: normal 30px/1.2 $font-headline;

		@include rem(font-size, 30px);
	}

	.boxes-arrows-image {
		width: $photo-thumbnail-width;

		@include until($tablet-alt) {
			height: auto;
		}

		@include until($tablet-alt) {
			width: 100%;
		}

		> img {
			width: 100%;
		}
	}

	.boxes-arrows-info {
		width: auto;
		margin-left: $photo-thumbnail-width;
		min-height: 260px;
		display: block;
	}

	.boxes-arrows-item {
		&:nth-child(2n) {
			.boxes-arrows-info {
				margin-left: 0;
				margin-right: $photo-thumbnail-width;
			}
		}

		@include until($tablet-alt) {
			float: left;
			width: 50%;
			border-top: 1px solid $border-boxes-arrows;
			border-bottom: 0;

			.boxes-arrows-info {
				margin: 0;
				float: left;
			}

			&:nth-child(2n) {
				clear: left;

				.boxes-arrows-info {
					margin-right: 0;
				}
			}

			&:last-child {
				border-bottom: 0 none;
			}
		}

		@include until($phone-landscape) {
			float: none;
			width: 100%;
			min-height: 10px;

			.boxes-arrows-info {
				min-height: 10px;
				width: 100%;
			}
		}
	}
}
