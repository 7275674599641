.next-article {
	&.next-article {
		background-color: $black;
		background-size: cover;
		height: 160px;
		text-align: center;
		color: $color-brand-white;
		position: relative;
		display: block;
		transition: all 0.2s ease-in;

		&:hover {
			color: $color-brand-white;
			background-color: darken($nav-link-hover, 20%);
		}

		&--background-image {
			&:hover {
				&:after {
					opacity: 1;
				}
			}
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: #386b62;
			opacity: 0;
			z-index: 1;
			transition: opacity 0.2s ease-in;
		}

		@include until($phablet) {
			padding: 35px 0;
			height: auto;
		}

		&.content-banner {
			padding: 50px 0;
			height: auto;
			background-color: $white;

			&:after {
				display: none;
			}

			h2 {
				top: 20%;
				font: 400 37px/1 $font-brand;
				color: $black;
			}

			.subtitle {
				display: block;
				color: $black;
				margin-top: 10px;
			}

			.link {
				display: inline-block;
				padding: 10px 16px;
				outline: solid 1px;
				line-height: 1;
				letter-spacing: 1px;
				font: 400 11px/1 "AvenirLTW04-95Black", "avenir", Helvetica, Arial, sans-serif;
				cursor: pointer;
			}
		}

		&.highlight-banner {
			height: auto;
			padding: 50px 0px;
			z-index: 2;

			&:hover {
				background-color: $color-brand-black;
			}

			&:after {
				display: none;
			}

			h3 {
				top: 0;
				transform: none;
				font-size: 26px;
			}

			.subtitle {
				display: block;
			}

			.highlight-container {
				display: flex;
				padding: 20px 0px;
			}

			.highlight-item {
				width: 33.333%;
				color: white;

				&__title {
					display: block;
					font-size: 18px;
					font-family: $font-brand;
					margin-top: 10px;
				}

				&__stat {
					display: block;
					font-family: "AvenirLTW04-95Black", sans-serif;
					font-size: 90px;
					line-height: 1;
				}

				&__link {
					display: inline-block;
					margin-top: 20px;
					padding: 10px 16px;
					outline: solid 1px;
					line-height: 1;
					letter-spacing: 1px;
					font: 400 11px/1 "AvenirLTW04-95Black", "avenir", Helvetica, Arial, sans-serif;
					cursor: pointer;
				}
			}

			@include until($phone-wide) {
				.highlight-container {
					flex-wrap: wrap;
				}

				.highlight-item {
					width: 100%;
					margin-bottom: 40px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		h3 {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			margin: 0 auto;
			font: 700 60px/1.1 $font-headline;
			text-align: center;
			z-index: 2;

			@include until($large-screen) {
				font-size: 50px;
			}

			@include until($tablet) {
				font-size: 40px;
			}

			@include until($phablet) {
				font-size: 33px;
				top: 0;
				transform: translateY(0);
			}
		}
	}
}
