/* offices listing */

.office-item-title {
	@include rem(font-size, 35px);

	font-family: $font-headline;
	line-height: 1.3;
	margin: 10px 0 0;

	@include until($phone-wide) {
		margin-top: 0;
	}

	> a {
		color: $black;

		&:hover {
			color: $nav-link-hover;
		}
	}
}

.office-item-thumbnail {
	img {
		max-width: 95%;
	}
}

.office-item {
	margin-top: 5%;

	@include rem(font-size, 16px);

	@include until($phone-wide) {
		margin-top: 10%;
	}

	> p {
		margin-top: 10px;
	}

	span {
		display: block;
		font-style: normal;
	}

	address {
		font-style: normal;
	}

	.btn {
		margin-top: 20px;
	}
}
/* offices listing */
