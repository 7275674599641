.article-body {
  .rich-text {
    p:first-child {
      &:first-letter {
        display: inline-block;
        font-size: 85px;
        line-height: 0.8;
        font-family: $font-headline;
        color: $black;
        float: left;
        margin: -13px 5px 0 0;

        @include until($tablet) {
          margin-top: -16px;
        }
      }
    }
  }

  &.article-results-body {
    overflow: hidden;
  }
}

.article-source {
  font-style: italic;
}
.article-title {
  h1 {
    font: normal 35px/1.2 $font-brand-secondary;
    color: $black;
    @include rem(font-size, 35px);

    @include until($tablet-alt) {
      @include rem(font-size, 30px);
    }
  }
}

.article-details {
  background-color: $bg-article-details;
  border: 1px solid $border-related-content;
  padding: 25px 30px;
  width: 240px;
  font-size: 14px;
  line-height: 1.5;
  margin: 5px -35px 15px 15px;

  @include until($tablet) {
    margin-right: 0;
    padding: 15px 20px;
    width: 200px;
  }

  @include until($phablet) {
    float: none;
    width: auto;
    margin-bottom: 30px;
  }

  time {
    font-size: 16px;
    line-height: 1.5;
  }

  .list {
    li {
      margin-bottom: 5px;
    }

    .list-link {
      font-style: italic;
      font-size: 16px;
      line-height: 1.4;
    }
  }

  .list + .list,
  time + .list {
    &:before {
      display: inline-block;
      content: "";
      width: 48px;
      height: 1px;
      background-color: $border-related-content;
      margin-bottom: 5px;
    }
  }

  .list-related {
    > li {
      margin-bottom: 0;

      &:after {
        content: ",";
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }
}

.articles-nav {
  $nav-margin: 10px;

  @include rem(font-size, 15px);

  .articles-nav-link-container {
    transform: translateX(255px);
    transition: transform 0.3s ease-in, width 0.3s ease-in, background-color 0.3s ease-in;
    background-color: $color-accent-grey;
    display: inline-block;
    width: 380px;
    position: absolute;
    margin-top: $content-section-padding;
    margin-bottom: $content-section-padding;
    z-index: 10;
    right: 0;

    &--prev {
      transform: translateX(-255px);
      left: 0;
      right: auto;
    }

    .articles-nav-link {
      color: $black;
      background-color: $color-accent-grey;
      position: relative;
      right: 0;
      z-index: 10;
      padding: 15px 10px 15px 25px;
      width: 100%;
      display: inline-block;

      &:hover {
        .articles-nav-label,
        .articles-nav-page-label {
          transform: translateX(40px);
        }

        color: $nav-link-hover;
      }

      .articles-nav-label {
        transition: transform 0.3s ease-in;
      }

      .fa {
        transition: transform 0.3s ease-in;
        margin-left: $nav-margin;
      }

      .articles-nav-page-label {
        transition: transform 0.3s ease-in;
      }

      &--prev {
        padding: 15px 25px 15px 10px;
        text-align: right;

        &:hover {
          .articles-nav-label,
          .articles-nav-page-label {
            transform: translateX(-40px);
          }
        }

        .fa {
          margin-right: $nav-margin;
          margin-left: 0;
        }
      }
    }
  }
}

.articles-nav-label {
  font: 30px/1 $font-headline;
  width: 84px;
}

.articles-nav-label,
.articles-nav-page-label {
  display: inline-block;
  vertical-align: middle;
}

.articles-nav-page-label {
  padding: 0 18px;
}

@include until($tablet + 1) {
  .articles-nav {
    overflow: hidden;

    .articles-nav-link-container {
      margin-top: $content-section-padding-phablet;
      position: relative;
      width: 100%;
      float: left;
      left: auto;
      right: auto;
      transform: translateX(0);

      .articles-nav-link {
        &--prev {
          text-align: left;
        }

        &:hover {
          .articles-nav-label,
          .articles-nav-page-label {
            transform: translateX(0);
          }

          .articles-nav-label {
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .articles-nav-label {
    @include rem(font-size, 20px);

    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  .articles-nav-page-label {
    margin-left: 80px;
  }
}
