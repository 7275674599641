.people-related-services {
	& + & {
		margin-top: 30px;
	}
}

.people-related-services-label {
	@extend %uc;

	color: $services-label;
	font: normal 16px $font-brand-secondary;

	margin-top: 5px;

	@include rem(font-size, 16px);

	@include until($tablet) {
		width: 33.333333%;
	}
}

.people-related-services-list {
	@include until($tablet) {
		width: 66.666667%;
	}

	@include until($phablet) {
		width: 100%;
	}

	> a {
		color: $services-label;

		&:after {
			content: ",";
			display: inline-block;
			margin-right: 5px;
		}

		&:last-child {
			&:after {
				content: "";
				margin: 0;
			}
		}

		&:hover {
			color: $nav-link-hover;
		}
	}

	label {
		&:hover {
			cursor: pointer;
		}
	}
}
