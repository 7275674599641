.boxes-arrows {
	.section-title {
		@include rem(margin-bottom, 60px);
	}

	.grid-col-halfs {
		@include until($tablet-alt) {
			width: 100%;
			float: none;
		}
	}

	.boxes-arrows-text-wrap {
		max-width: 450px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
	}

	.boxes-arrows-item {
		//for even items flip the order of the image and text
		&:nth-child(2n) {
			.boxes-arrows-image {
				left: auto;
				right: 0;
			}

			.boxes-arrows-info {
				margin-left: 0;
				justify-content: flex-end;

				//arrow
				&:before {
					left: auto;
					right: -13px;
					border-width: 0 1px 1px 0;

					@include until($tablet-alt) {
						top: 0;
						left: 50%;
						right: auto;

						transform: translate(0, -50%) rotate(-45deg);
						border-width: 1px 1px 0 0;
					}
				}
			}
			.boxes-arrows-image {
				> img {
					float: left;
				}
			}
		}
	}
}

.boxes-arrows-item {
	display: block;
	position: relative;
	height: 100%;
	color: $black;
	border: solid 1px $med-gray;

	&:hover {
		color: $color-brand-white;

		.boxes-arrows-info {
			background-color: $black;
			border-color: $black;

			&:before {
				background-color: $black;
				border-color: $black;
			}
		}

		.boxes-arrows-image {
			//background color
			&:before {
				opacity: 0.3;
			}
			//read-more
			&:after {
				opacity: 1;
			}
		}
	}
}

.boxes-arrows-image {
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;

	@include until($tablet-alt) {
		position: static;
		left: auto;
		top: auto;
		height: auto;
	}

	> img {
		display: block;

		width: 100%;
		height: 100%;
		object-fit: cover;

		@include until($large-screen) {
			min-height: 100%;
			width: auto;
		}

		@include until($tablet-alt) {
			min-height: 10%;
			width: 100%;
		}
	}

	&:before,
	&:after {
		transition: opacity 0.4s ease-in;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $black;
		opacity: 0;
	}

	&:after {
		@extend %uc;
		@extend .btn;

		content: "Read More";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-color: $color-brand-white;
		padding: 8px 16px;
		color: $color-brand-white;
		opacity: 0.00001;

		@include until($tablet-alt) {
			top: 25%;
		}
	}
}

.boxes-arrows-info {
	padding: 30px 40px;
	margin-left: 50%;
	display: flex;
	float: none;
	position: relative;
	min-height: 200px;

	transition: background-color 0.4s ease-in;

	@include until($tablet-alt) {
		margin-left: 0;
		min-height: 10px;
	}

	@include until($phone-wide) {
		padding: 20px;
	}

	blockquote {
		margin: -30px 0 0 -30px;
		padding: 30px 0 15px 30px;
		position: relative;
		max-height: 149px;
		overflow: hidden;

		@include until($large-screen) {
			max-height: 175px;
			min-height: 110px;
		}

		@include until($tablet-alt) {
			max-height: none;
		}

		p {
			margin-top: 0;
		}

		p + p {
			margin: 25px 0 0;
		}

		//quote watermark
		&:before {
			content: "\201c";
			position: absolute;
			font-size: 350px;
			line-height: 300px;
			left: 0;
			top: 0;
			color: $color-accent-grey;
			z-index: -1;
			opacity: 0.8;
		}

		& + .boxes-arrows-title {
			//line
			&:before {
				display: block;
				content: "";
				width: 48px;
				height: 1px;
				background-color: $border-related-results;
				margin-bottom: 15px;
			}
		}
	}

	//arrow

	&:before {
		content: "";
		position: absolute;
		left: -13px;
		top: 50%;

		transform: translateY(-50%) rotate(-45deg);
		width: 26px;
		height: 26px;
		background-color: $color-brand-white;
		// border: 1px solid $border-boxes-arrows;
		border-width: 1px 0 0 1px;

		transition: background-color 0.4s ease-in;

		@include until($tablet-alt) {
			top: 0;
			left: 50%;
			right: auto;

			transform: translate(0, -50%) rotate(-45deg);
			border-width: 1px 1px 0 0;
		}
	}
}

.boxes-arrows-tag {
	@include rem(font-size, 14px);

	color: $black;
	font-family: $font-primary;
	border-bottom: 1px solid $black;
	padding-bottom: 5px;
	transition: all 0.2s ease-in;

	.boxes-arrows-item:hover & {
		color: $color-brand-white;
		border-bottom: 1px solid $white;
	}
}

.boxes-arrows-title {
	font-family: $font-brand;
	color: $black;
	font-size: 1.25rem;
	line-height: 1.3;
	margin: 15px 0 0;
	transition: color 0.2s ease-in;

	.boxes-arrows-item:hover & {
		color: $color-brand-white;
	}
}
