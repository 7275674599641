.modal-overlay {
	background-color: $white;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	transition: opacity 0.2s ease-in;
	cursor: pointer;
	visibility: hidden;

	&.is-active {
		opacity: 0.5;
		z-index: 99;
		visibility: visible;
	}
}
