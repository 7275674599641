.logo {
	text-indent: -9999px;
	display: block;
	width: 100px;
	margin: 0 auto;
}

.logo-light {
	display: inline-block;
	width: 100px;
	height: 38px;
	background: url(../img/logo-main.png) no-repeat top left;
	position: relative;
	top: -10px;
}

.logo-main-alt {
	display: inline-block;
	width: 136px;
	height: 56px;
	background: url(../img/logo-main-alt.png) no-repeat top left;
	position: relative;
}

.logo-dark {
	position: relative;
	top: -27px;
	height: 36px;
	background: url(../img/logo-dark.png) no-repeat center center;
}

.logo-hero {
	width: 100%;
	height: 50px;
	background: url(../img/logo-hero.png) no-repeat center center;
	margin: 30px auto 30px;
}
