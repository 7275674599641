.results-social-icons {
	width: 20px;
	position: absolute;
	margin-left: -60px;

	@include until($tablet) {
		width: auto;
		text-align: right;
		position: static;
		margin-left: 0;
	}

	@include until($phablet) {
		margin-bottom: 10px;
	}
}

.results-social-icon {
	color: $color-brand-white;
	background-color: $nav-link-hover;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	font-size: 23px;

	& + & {
		margin-top: 20px;
	}

	&:hover {
		background-color: $black;
	}
}
