.next-services-list {
	background: url("../img/results-article-abstract.jpg") no-repeat center top;
	background-size: cover;

	text-align: center;
	color: $color-brand-white;
	position: relative;
	display: block;
	padding: 40px 0;

	@include until($phablet) {
		padding: 35px 0;
		height: auto;
	}
}

.list-next-services {
	overflow: hidden;

	> li {
		display: inline-block;
		font: normal 24px/1.2 $font-headline;

		@include until($tablet) {
			font-size: 18px;
		}
		&:after {
			content: "|";
			display: inline-block;
			margin-left: 5px;
			margin-right: 4px;
		}

		&:last-child {
			&:after {
				content: "";
				display: none;
			}
		}
	}
}

.list-next-serviceslink {
	color: $color-brand-white;
	display: inline-block;
}
