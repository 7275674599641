.section__news {
	padding: 80px 0;
	z-index: 4;

	-webkit-transform: translate3d(0, 0, 0);
}
.news__title--small {
	font-size: 18px;
	color: $light-gray;
	font-family: $secondary-font;
	transition: color 0.2s ease;
}
.news__title--large {
	margin: 5px 0 0 0;
	font-size: 45px;
	color: $light-teal;
	transition: color 0.2s ease;
	font-family: $headline-font;
	font-weight: 400;

	@media (max-width: 900px) {
		font-size: 35px;
	}
}

.news-container {
	margin: 80px auto;
	border: 1px solid $med-gray;
}

.news__image-wrapper {
	position: relative;
	text-align: center;

	&:before {
		content: "Read More";
		display: inline-block;
		padding: 6px 12px;
		color: $white;
		border: 2px solid $white;
		background-color: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -50px;
		z-index: 5;
		opacity: 0;
		font-family: $tertiary-font;
		font-weight: normal;

		transition: opacity 0.2s ease;
	}
}

.section__news {
	.row {
		display: block;

		@media (max-width: 767px) {
			display: table;
			width: 100%;
		}

		&:hover {
			text-decoration: none;

			.inner-padding {
				background-color: $light-teal;
			}

			.arrow-wrapper-right:after {
				border-right-color: $light-teal;
			}

			.arrow-wrapper-left:after {
				border-left-color: $light-teal;
			}

			.news__title--small,
			.news__title--large {
				color: $white;
			}

			[class^="arrow-wrapper"]:before {
				opacity: 0.5;
			}

			.news__image-wrapper:before {
				opacity: 1;
			}
		}
	}

	.col-2 {
		position: relative;

		@media (max-width: 767px) {
			display: table-header-group;
		}
	}

	.inner-padding {
		display: block;
		transition: background-color 0.2s ease;

		@media (min-width: 767px) {
			padding-bottom: 200px;
			margin-bottom: -200px;
		}
	}

	[class^="arrow-wrapper"]:after {
		@media (max-width: 767px) {
			display: none;
		}
	}

	[class^="arrow-wrapper"] {
		> img {
			display: block;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;

			background-color: $light-teal;
			opacity: 0;

			transition: opacity 0.2s ease;
		}
	}
}
