//About us Landing

.nav-landing {
	letter-spacing: -0.1em;
	line-height: 1;
	text-align: center;
}

.nav-landing-link {
	display: inline-block;
	letter-spacing: 0;
	width: 30.3333%;
	padding-bottom: 30%;
	margin: 1% 0 0 1%;
	overflow: hidden;
	color: $color-brand-white;
	position: relative;
	background-color: $color-brand-black;

	&:hover {
		color: $color-brand-white;
	}

	@include until($tablet) {
		width: 48%;
	}

	@include until($phone-wide) {
		width: 100%;
	}

	&:hover {
		.nav-landing-img {
			opacity: 1;
			transform: scale3d(1.1, 1.1, 1);
		}
	}
}

.nav-section-title {
	position: absolute;
	width: 100%;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	margin-top: 0;
	padding: 0 10px;
	z-index: 5;

	@include until($phablet) {
		@include rem(font-size, 30px);
	}
}

.nav-landing-img {
	display: block;
	position: absolute;
	height: 100%;
	opacity: 0.85;
	transition: opacity 0.35s ease-in, transform 0.35s ease-in;

	@include until($phablet) {
		width: 160%;
	}

	@include until($phone-wide) {
		width: 100%;
	}
}
