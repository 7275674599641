$section-hero-height: 400px;

.section__hero {
	height: $section-hero-height;
	background: url(../img/bg-hero.jpg) no-repeat center bottom;
	background-size: cover;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;

	transform: translateY(0);

	transition: transform 0.3s ease-in-out;

	//-webkit-transform: translate3d(0, 0, 0);

	.js-global-search-active & {
		transform: translateY(90px);
	}

	+ section {
		// 60px accounts for navigation
		margin-top: $section-hero-height + 37px;
	}

	.page-container {
		max-width: none;
		position: relative;
	}
}

.homepage {
	section {
		z-index: 10;
	}

	.section-home-news,
	.global-related-section,
	.home-locations {
		-webkit-transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
	}

	.home-locations,
	.global-footer {
		position: fixed;
		width: 100%;
		z-index: 1;
		visibility: hidden;
		opacity: 0;

		&.is-activated {
			visibility: visible;
			opacity: 1;
		}

		@include until($tablet) {
			position: static;
			visibility: visible;
			opacity: 1;
		}
	}

	.global-footer {
		bottom: 0;

		@include until($tablet) {
			bottom: auto;
		}
	}

	@include until($tablet) {
		.global-related-section {
			margin-bottom: 0 !important;
		}
	}
}

.hero__circle-svg {
	position: absolute;
	top: 20%;
	right: 31%;
	width: 85px;
}

.hero__main--title {
	color: $white;
	font-size: 54px;
	line-height: 1;
	font-family: $font-brand;
	opacity: 0;
	margin: 60px 0 -20px;
	transform: translateY(50px);

	&.is-animating {
		transition: all 0.5s ease-in-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

.hero__main--subtitle {
	color: $white;
	font-family: $secondary-font;
	font-weight: 400;
	font-size: 20px;
	margin: 0;
	margin-top: 30px;
	opacity: 0;
	display: inline-block;
	transform: translateY(50px);

	&.is-animating {
		transition: all 0.5s ease-in-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

.hero__main--arrow {
	display: block;
	color: $color-brand-white;
	font-size: 82px;
	line-height: 1;
	width: 50px;
	margin: 0px auto 0;
	z-index: 4;
	cursor: pointer;
	opacity: 0;
	transform: translateY(100px);

	&:hover {
		color: $color-brand-white;
	}

	&.is-animating {
		transition: all 0.5s ease-in-out;
		transform: translateY(0px);
		opacity: 0.8;

		&:hover {
			opacity: 1;
		}
	}
}

.logo-hero {
	border-right: 3px solid transparent;
	border-left: 3px solid transparent;
	transition: width 0.5s ease-in-out;

	-webkit-transform: translate3d(0, 0, 0);
	background-size: 145px !important;
	&.is-animating {
		width: 152px;
		border-color: $white;
		background-size: 145px;
	}
}

@media (max-width: 767px) {
	.hero__main--title {
		font-size: 30px;
		margin: 40px 0 -20px;
	}
	.section__hero {
		position: static;
		height: auto;
		margin-top: 30px;

		.js-global-search-active & {
			transform: translateY(60px);
		}

		.logo-hero {
			width: 0;
			height: 20px;
			opacity: 0;
		}
		+ section {
			margin-top: 0;
		}
	}

	.hero__circle-svg {
		position: absolute;
		top: 12%;
		right: 7%;
		width: 65px;
	}
}
