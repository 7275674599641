.filter-tags-options {
	overflow: hidden;
}

.filter-tag {
	font: normal 26px/1.1 $font-headline;
	@include rem(font-size, 24px);

	display: block;
	float: left;
	height: 100px;

	background-color: $color-accent-green-light;
	color: $color-brand-white;
	border: 1px solid $color-brand-white;
	width: 20%;
	position: relative;

	@include until($large-screen) {
		width: 25%;
	}

	@include until($tablet) {
		width: 33.333333%;
	}

	@include until($phone-wide) {
		width: 50%;
	}

	&.is-active,
	&:hover {
		background-color: $color-accent-green;
	}

	> span {
		position: absolute;
		top: 50%;
		padding: 0 10px;
		transform: translateY(-50%);
		display: block;
		text-align: center;
		width: 100%;
	}
}
