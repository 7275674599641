$content-section-padding: 50px;
$content-section-padding-phablet: 30px;
$base-spacing: 30px;

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  padding-top: 37px;

  @include until($tablet) {
    padding-top: 33px;
  }

  &.homepage {
    padding-top: 0;
  }
}

.page-wrap {
  max-width: 1010px;
  padding: 0 15px;
  margin: 0 auto;

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.content-section {
  margin: 0 auto;
  max-width: 680px;
  padding: 0 15px;
  position: relative;

  & + & {
    margin-top: $content-section-padding;
    padding-top: $content-section-padding;
    border-top: 1px solid $border-content;

    @include until($phablet) {
      margin-top: $content-section-padding-phablet;
      padding-top: $content-section-padding-phablet;
    }
  }

  &-bg-wrap {
    width: 100%;
    margin-bottom: 100px;
    padding: 50px 0;
    background-color: $white;
    background-image: url("/assets/public/images/jomar-271602.jpg");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: relative;
    border-bottom: solid 2px $med-gray;
  }

  &-filter {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background-color: rgba($black, 0.5);
  }
}

p {
  margin: $base-spacing 0 0;
}

.global-section {
  padding: $content-section-padding 0;

  @include until($phablet) {
    padding: $content-section-padding-phablet 0;
  }
}

.global-section-grey {
  background-color: $color-accent-grey;
}

.global-main-content {
  position: relative;
  padding-top: 50px;

  .transition-text {
    text-align: center;
    font-size: 34px;
    font-family: $font-brand;
    margin-top: 60px;
  }

  &.no-padding {
    padding-top: 0;

    .content-section-bg-wrap {
      background-image: none;
    }
  }
}

.content-related-pages {
  overflow-x: hidden;
}

.global-highlight-section {
  background-color: $color-accent-grey;
  position: relative;
}

.global-related-section {
  background-color: $color-accent-grey;
  position: relative;

  @include until($phablet) {
    padding-top: 60px;
  }

  .content-section-related + .content-section-related {
    margin-top: $content-section-padding;
  }

  .homepage & {
    z-index: 10;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.3);
  }
}
