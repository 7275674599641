.filter-dropdown {
	position: relative;

	display: inline-block;
	width: auto;
	z-index: 5;

	> span {
		@include rem(font-size, 14px);
		display: inline-block;
		font-style: italic;
		font-weight: normal;
		font-family: $font-primary;
		cursor: pointer;
		padding: 0 10px;
		border: 0 none;
		text-transform: none;

		&.active-nav {
			& + .filter-dropdown-options {
				opacity: 1;
				max-height: 500px;
				visibility: visible;
			}
		}

		&:after {
			content: "\f078";
			display: inline-block;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			margin-left: 5px;
		}
	}
}

.filter-dropdown-options {
	position: absolute;
	width: 100%;
	top: 100%;
	left: 0;
	background-color: $color-brand-white;
	border-bottom: 1px solid $border-related-results;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: max-height 0.35s ease-in, opacity 0.35s ease-in;
}

.filter-dropdown-item {
	@include rem(font-size, 14px);

	padding: 5px 10px;
}
