.search-criteria-options {
	display: table;
	width: 100%;
	padding-bottom: 20px;
	border-bottom: 1px solid $border-related-content;

	& + & {
		border-bottom: 0;
		padding-top: 10px;
	}

	@include until($phablet) {
		display: block;
	}
}

.search-criteria-results {
	.search-criteria-options & {
		padding-bottom: 10px;
	}

	.search-criteria-options:first-child {
		display: table;

		.search-criteria-section {
			display: table-cell;
		}
	}
}

.search-criteria-section,
.search-criteria-filters,
//this line just for legacy sake since class names were adjustend on teh professional landing and results page
.search-criteria-options >.search-criteria-results {
	display: table-cell;
	//vertical-align: bottom;

	@include until($phablet) {
		display: block;
	}
}

.search-criteria-filters {
	text-align: right;

	@include until($phablet) {
		text-align: left;
	}
}

.search-criteria-item {
	@include rem(font-size, 14px);
	line-height: 1em;
	margin-right: 2%;

	@include until($tablet) {
		@include rem(font-size, 16px);
	}

	.fa {
		color: $bg-clear-button;
		transform: rotate(45deg);
		margin-left: 5px;
	}
}

.search-criteria-filter-item {
	@include rem(font-size, 14px);

	font-style: italic;
	text-decoration: underline;
	margin-left: 5px;
}

.search-criteria-label {
	@extend .btn;
	@extend .btn-text;

	@include rem(font-size, 14px);

	@include until($tablet) {
		@include rem(font-size, 16px);
	}

	&:hover {
		color: $black;
	}
}

$toggle-dimension: 23px;
//List views and Squares
.search-criteria-toggle-view {
	text-align: right;
}
.search-criteria-toggle-grid {
	display: inline-block;
	width: $toggle-dimension;
	height: $toggle-dimension;
	overflow: hidden;
	vertical-align: bottom;

	.search-criteria-toggle-squares {
		display: block;

		&:before,
		&:after {
			content: "";
			width: 11px;
			height: 11px;
			float: left;
			display: block;
			background-color: $btn-view-toggle-color;
			margin-bottom: 1px;
			transition: background-color 0.3s ease-in;
		}

		&:before {
			margin-right: 1px;
		}
	}

	&:hover,
	&.is-active {
		.search-criteria-toggle-squares {
			&:after,
			&:before {
				background-color: $black;
			}
		}
	}
}

.search-criteria-toggle-list {
	display: inline-block;
	width: 25px;
	height: $toggle-dimension;
	overflow: hidden;
	margin-left: 5px;
	vertical-align: bottom;

	//probably should have used icon
	.search-criteria-toggle-hamburger {
		display: block;
		background-color: $btn-view-toggle-color;
		width: 100%;
		height: 5px;
		position: relative;
		transition: background-color 0.3s ease-in;

		&:before,
		&:after {
			content: "";
			display: block;
			background-color: $btn-view-toggle-color;
			width: 100%;
			height: 5px;
			position: absolute;
			top: -9px;
			left: 0;
			transition: background-color 0.3s ease-in;
		}

		&:after {
			bottom: -9px;
			top: auto;
		}
	}

	&:hover,
	&.is-active {
		.search-criteria-toggle-hamburger {
			background-color: $black;

			&:before,
			&:after {
				background-color: $black;
			}
		}
	}
}
