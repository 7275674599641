.site-search {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	background-color: $white;
	box-shadow: inset 0px -2px 4px 0px rgba(0, 0, 0, 0.2);
	height: 90px;
	top: -90px;
	transition: top 0.3s ease-in-out;

	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;

	.homepage & {
		z-index: 100;
	}

	@include until($tablet) {
		height: 60px;
		top: -60px;
	}

	&.is-active {
		top: 0;
	}

	input {
		background-color: transparent;
		border: 0 none;
		margin: 0;
		padding-left: 0;
		font-size: 30px;
		font-style: normal;
		width: 79.999%;
		display: inline-block;
		padding: 25px 10px 25px 0;

		@include until($tablet) {
			font-size: 20px;
			padding: 20px 10px 20px 0;
		}
	}

	button {
		background-color: transparent;
		width: 20%;
		float: right;
		text-align: right;
		padding: 24px 0;
		font-size: 35px;

		@include until($tablet) {
			padding: 15px 0;
			font-size: 25px;
		}
	}

	input,
	button {
		color: $black;
	}
}

.input-wrapper-site-search {
	overflow: hidden;
}
