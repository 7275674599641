$img-dimension: 149px;
$img-border: 50%;

.related-people {
	@include until($phablet) {
		padding-top: $content-section-padding-phablet;
	}
}

.related-people-list {
	margin-top: $content-section-padding;
	letter-spacing: -5px;
	text-align: center;

	@include until($phablet) {
		margin-top: $content-section-padding-phablet;
	}

	.grid-col-3 {
		text-align: center;
		margin-bottom: 35px;
		display: inline-block;

		vertical-align: top;
		float: none;
	}
}

.related-people-link,
.related-people-wrap {
	display: inline-block;
}

.related-people-link {
	color: $black;
	letter-spacing: 0;
	h3 {
		font: normal 30px/1.1 $font-headline;

		@include rem(font-size, 30px);
		margin: 24px 0 0;
	}
	&:hover {
		color: $light-teal;
	}
}

.related-people-wrap {
	width: $img-dimension;
	position: relative;

	&:after {
		position: absolute;
		content: "";
		display: block;
		top: 0;
		left: 0;

		width: $img-dimension; //width: 250px;
		height: $img-dimension; //height: 250px;
		border-radius: $img-border;
		border: 0 solid $white;
		opacity: 0;
		transition: opacity 0.3s ease-in, border 0.3s ease-in;

		.related-people-link:hover & {
			border-width: 4px;
			opacity: 1;
		}
	}
}

.related-people-img {
	border-radius: $img-border;
	max-width: 100%;
}

.related-people-meta {
	@include rem(font-size, 16px);

	line-height: 1.4;
	margin-top: 5px;
}

.related-people-search {
	width: 60%;
	margin: $content-section-padding auto 0;

	@include until($phablet) {
		width: 85%;
	}

	@include until($phone-wide) {
		width: 90%;
	}
}
