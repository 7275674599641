$iso-box-spacing: 10px;
$iso-box-height: 220px;

.iso-canvas {
	position: relative;
	overflow: hidden;
	margin-left: -$iso-box-spacing;

	@include until($phablet) {
		margin-left: -15px;
	}
}

.iso-box {
	background: no-repeat center top;
	background-size: 100%;
	float: left;
	width: 50%;
	height: $iso-box-height/2;
	border-left: $iso-box-spacing solid $color-brand-white;
	border-top: $iso-box-spacing solid $color-brand-white;

	color: $color-brand-white;
	padding: 30px 40px;
	cursor: pointer;

	transition: background 0.35s ease-in;

	&:hover {
		color: $color-brand-white;
		background-size: 110%;
	}

	h2,
	h3 {
		margin: 0;
		font: normal 45px/1.3 $font-headline;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;

		@include rem(font-size, 45px);

		@include until($large-screen) {
			@include rem(font-size, 40px);
		}
	}

	@include until($phablet) {
		width: 100%;
		border-left-width: 15px;
	}
}

#isotope {
	float: none;
}

.iso-parent-box {
	height: $iso-box-height;
	background-color: $black;

	&.is-extended {
		background-color: $black;
		background-image: none !important;

		height: auto;

		min-height: $iso-box-height * 2;

		h2 {
			top: 0;
			transform: translateY(0);
		}
	}

	h2 {
		@include rem(font-size, 50px);
		line-height: 1.1;

		@include until($large-screen) {
			@include rem(font-size, 40px);
		}

		@include until($tablet) {
			@include rem(font-size, 32px);
			margin: 0;
			padding: 0;
			top: 0;
			transform: translateY(0);
		}
	}

	@include until($tablet) {
		height: $iso-box-height/2;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.iso-parent-box-custom {
	@include until($tablet) {
		height: $iso-box-height/2;
		display: block;
	}
}

.iso-box-related {
	background-color: $color-accent-grey;
	color: $black;
	transition: all 0.3s ease-in;
	padding: 20px 15px;

	h3 {
		@include rem(font-size, 36px);

		line-height: 1.1;

		@include until($large-screen) {
			@include rem(font-size, 30px);
		}

		@include until($tablet) {
			@include rem(font-size, 28px);
		}
	}

	&:hover {
		background: $black;
		color: $white;
	}
}

.iso-box-other {
	background-color: $color-brand-white;
	padding: 0;
	height: auto;

	h3 {
		@include rem(font-size, 40px);

		color: $black;
		position: static;
		top: 0;
		transform: translateY(0);
		margin-bottom: 10px;

		@include until($large-screen) {
			@include rem(font-size, 28px);
		}
	}

	.iso-wrap {
		padding: 30px 25px;
		border: 1px solid $border-boxes-arrows;
	}

	ul {
		padding-left: 0;
	}
}
.iso-box-other-link {
	@include rem(font-size, 18px);

	line-height: 1.4;
	color: $black;
	display: inline-block;
	margin-top: 8px;

	@include until($large-screen) {
	}
	&:hover {
		color: $color-body-links;
	}
}

.iso-parent-box-abstract {
	display: none;

	.is-extended & {
		display: block;
	}
}

.services-landing-nav {
	margin-left: -$iso-box-spacing;
	padding-bottom: $content-section-padding;
	overflow: hidden;
}

.services-landing-nav-link {
	@extend %uc;

	float: left;
	width: 50%;
	display: block;
	border-left: $iso-box-spacing solid $color-brand-white;

	text-align: center;
	color: $black;
	font-family: $font-brand;
	font-weight: 400;

	span {
		display: inline-block;
		padding-top: 8px;
		border-top: 3px solid transparent;
		position: relative;
		text-align: center;

		&:before {
			position: absolute;
			margin-top: -10px;
			content: "";
			background-color: $border-section-title;
			width: 33px;
			height: 2px;
			left: 50%;
			transform: translateX(-50%);
			transition: width 0.2s ease-out, background-color 0.2s ease-out;
		}
	}

	&.is-active,
	&:hover {
		span {
			&:before {
				width: 100%;
				background-color: $black;
			}
		}
	}
}
