.results-landing-content {
	margin-top: $base-spacing + 10px;
}

.results-landing-articles {
	margin-top: $base-spacing + 10px;
}

.results-listing-list,
.results-listing-grid {
	display: none;
}
.newsletter-landing {
	margin-top: 40px !important;
	.results-listing-grid .results-listing-item {
		width: 100%;
	}
}
.results-listing-grid {
	.grid-col-halfs {
		@include until($phablet) {
			margin-top: 30px;
		}
	}
}

.recent-news-container {
	margin-top: 60px;
	// + .recent-news-container {
	// }
}

.results-listing-item {
	border: 1px solid $border-boxes-arrows;
	padding: 30px;
	color: $black;
	display: block;

	.display-date {
		margin-top: 10px;
		@include rem(font-size, 14px);
	}

	.results-listing-item & {
		width: 50%;
	}

	.abstract {
		@include rem(font-size, 16px);

		border-top: 1px solid $border-boxes-arrows;
		padding-top: 25px;
		margin-top: 25px;
	}
	&.experience-brief {
		&:hover {
			.results-listing-item-title {
				color: $black;
			}
		}
	}

	&:hover {
		border-color: darken($border-inputs, 10%);

		.results-listing-item-title {
			color: $light-teal; //$nav-link-hover;
		}
	}
}

.results-listing-item-tag {
	@include rem(font-size, 14px);

	display: block;
	text-transform: capitalize;
}
.results-listing-item-tag {
	@include rem(font-size, 14px);

	display: block;
}

.results-listing-item-title {
	@extend .news-titles;

	transition: color 0.2s ease-in;
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	i {
		font-size: 14px;
	}
}

.results-listing-item-img {
	position: relative;
	margin: -31px -30px 0;

	> img {
		width: 100%;
	}

	.arrow {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1.85rem;
		text-align: center;
		overflow: hidden;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 50%;
			height: 23px;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: $color-brand-white;
			margin-left: -14px;

			@include until($phablet) {
				margin-left: -15px;
			}
		}

		&:after {
			left: auto;
			right: 0;

			margin-left: 0px;
			margin-right: -14px;

			@include until($phablet) {
				margin-right: -15px;
			}
		}
	}

	.down-arrow {
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		position: relative;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			bottom: 0;
			border-bottom: 0.75rem solid $color-brand-white;
		}

		&:before {
			border-right: 0.75rem solid transparent;
			left: 0;
		}

		&:after {
			border-left: 0.75rem solid transparent;
			right: 0;
		}

		@include until($phablet) {
			width: 30px;
			height: 30px;

			&:before,
			&:after {
				border-bottom-width: 15px;
			}

			&:before {
				border-right-width: 15px;
				left: 0;
			}

			&:after {
				border-left-width: 15px;
			}
		}
	}
}

.news-search-criteria,
.results-landing-content {
	.grid-row-padded {
		margin-bottom: 100px;
	}
}

.global-main-content-news {
	.results-listing-item {
		@include until($tablet-alt) {
			margin-top: 20px;
		}
	}

	.results-listing-item + .results-listing-item {
		margin-top: 20px;
	}

	.recent-news-block + .recent-news-block {
		margin-top: 100px;
	}
}
