//greys
$grey-med: #d5d5d5;
$grey-light: #f2f2f2;
$grey-light-alt: #dcdcdc;
$grey-med-dark: #e6e6e6;
$grey-med-alt: #dad9d7;
$grey-dark: #7d7d7d;

//browns
$brown: #645748;
$brown-light: #998772;
$beige: #b8aea2;
$brown-accent: #a3705d;
$brown-dark: #332f2a;

$beige-alt: #cec7c0;
$brown-dark-alt: #81776b;

//Blue
$blue: #122632;

//yellow
$yellow: #f7a11a;

//orange
$orange: #f18903;

//green
$green: #609b90;
$green-light: #9ec3bc;

//opacities
$opaquewhite: rgba(255, 255, 255, 0.5);
$opaqueblack: rgba(0, 0, 0, 0.8);
$site-opaque-bg: rgba(0, 0, 0, 0.5);

//colors
$color-brand-black: #000;
$color-brand-white: #fff;

$color-brand-blue: $blue;
$color-brand-brown: $brown;
$color-brand-beige: $beige;

$color-accent-yellow: $yellow;
$color-accent-orange: $orange;
$color-accent-brown: $brown-accent;
$color-accent-green: $green;
$color-accent-green-light: $green-light;
$color-accent-grey: $grey-light;

//text colors
$color-body-text: $brown-dark;
$color-body-links: $color-accent-green;
$color-text-titles: $color-brand-blue;
$color-text-subtitles: $color-brand-brown;
$color-text-headline: $color-brand-blue;
$color-text-service-title: $beige;

//links
$color-globalnav-links: $brown-light;
$color-globalnav-links-alt: $brown;
$color-content-links: $brown-light;

$color-article-nav: $brown-dark-alt;
$people-advanced-btn: $brown-dark;

//buttons
$btn-view-toggle-color: $grey-light-alt;
$btn-view-toggle-hover: $color-brand-blue;

//borders
$border-color-header: $grey-med;
$border-global-nav-current: $yellow;
$border-related-content: $grey-med-dark;
$border-related-results: $grey-light-alt;
$border-boxes-arrows: $grey-light-alt;
$border-content: $beige-alt;
$border-section-title: $beige;
$border-inputs: $grey-light-alt;

//backgrounds
$bg-article-details: $grey-light;
$bg-related-tabs: $color-accent-green-light;
$bg-related-tabs-current: $color-accent-green;
$bg-load-more: $color-accent-yellow;
$bg-filter-tag: $color-brand-beige;
$bg-filter-tag-hover: $color-brand-brown;
$bg-clear-button: $grey-med-alt;
$bg-home-locations: #386b62;

// Fonts
///////////////////////////////////////////////////////////////////////////////

$font-primary: georgia, serif;
$font-headline: "bebas-neue", Helvetica, Arial, sans-serif;
$font-brand: "AvenirLTW04-95Black", "avenir", Helvetica, Arial, sans-serif;
$font-brand-secondary: "AvenirLTW04-85Heavy", Helvetica, Arial;

//  Brandon Mathis Media Queries
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//    You should use the Breakpoint Aliases (below) when referencing media query
//    breakpoints with your custom CSS
//
///////////////////////////////////////////////////////////////////////////////

$phone: 320px;
$phone-landscape: 480px;
$phone-wide: 500px;
$phablet: 600px;
$tablet-alt: 700px;
$tablet: 768px;
$tablet-nav: 840px;
$large-screen: 950px;
$larger-screen: 1024px;

@mixin at-least($device-width) {
  @media screen and (min-width: $device-width) {
    @content;
  }
}

@mixin until($device-width) {
  @media screen and (max-width: ($device-width - 1)) {
    @content;
  }
}

//  Custom Icons
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//    You should use the Breakpoint Aliases (below) when referencing media query
//    breakpoints with your custom CSS
//
///////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: "shook";
  src: url("../fonts/shook.eot");
  src: url("../fonts/shook.eot?#iefix") format("embedded-opentype"),
       url("../fonts/shook.ttf") format("truetype"),
       url("../fonts/shook.svg#shook") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-Shook"],
[class*="icon-Shook"] {
  font-family: "shook" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-Shook"]:before,
[class*="icon-Shook"]:before {
  position: relative;
  z-index: 3;
}

.icon-Shook_pdf:before {
  content: "\e601";
}

.icon-Shook_vcard:before {
  content: "\e602";
}

.icon-Shook_linkedin:before {
  content: "\e600";
}
