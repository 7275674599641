$people-search-input-width: 75%;

.people-search {
	@extend %clearChildren;

	.input-large {
		width: $people-search-input-width;

		@include until($phablet) {
			width: 100%;
			float: none;
		}
	}
}

.people-search-advance-btn {
	margin-left: $people-search-input-width;
	display: block;
	padding-left: 15px;
	padding-top: 15px;
	color: $people-advanced-btn;
	font: 700 14px/1.2 $font-brand;

	&:hover,
	&.is-active {
		.custom-icon {
			color: $nav-link-hover;
		}
	}

	@include until($phablet) {
		margin-left: 0;
		padding-left: 0;
	}

	.custom-icon {
		float: left;

		&:before {
			font-size: 40px;
			width: 40px;
		}
	}

	&.is-active {
		.custom-icon {
			&:before {
				content: "\2013";
			}
		}
	}
}
