.input-wrap {
	position: relative;
	overflow: hidden;
	width: 50%;

	button {
		width: 20%;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
	}
}

input,
textarea {
	padding: 15px 10px;
	font: italic 18px/1.1 $font-primary;
	background: $color-brand-white;
	width: 100%;
	border: 0 none;
	outline: 0;
}

.input-large {
	width: 100%;

	input {
		width: 85%;
		padding: 20px 16px;
	}

	button {
		width: 15%;
		font-size: 25px;
		color: $black;
		text-align: right;
		padding-right: 20px;

		&:hover {
			color: rgba($black, 0.5);
		}
	}
}
