.related-tabs {
}

$tabs-white-space: 4px;

.related-tabs-nav {
	padding-top: $content-section-padding/2;
	margin-left: -$tabs-white-space;

	@include until($phablet) {
		padding-top: $content-section-padding-phablet;
	}
}

.related-tabs-nav-link {
	background-color: $nav-link-hover;
	color: $color-brand-white;
	display: block;
	padding: 20px 0;
	border-left: $tabs-white-space solid $color-accent-grey;
	text-align: center;
	font: normal 25px/1 $font-headline;

	@include until($tablet) {
		font-size: 22px;
		padding: 15px 0;

		&.grid-col-3 {
			width: 33%;
		}
	}

	@include until($phablet) {
		width: 50%;
	}

	@include until($phone-wide) {
		float: left;
		font-size: 18px;
		padding: 10px 0;
	}

	&.is-active,
	&.is-current,
	&:hover {
		background-color: $black;
		color: $color-brand-white;
	}

	&:first-child {
		&:last-child {
			width: 100%;
		}
	}
}

.global-tab-content {
	.results-listing-item {
		padding: 25px 8px;
		border-width: 0;
		border-bottom: 1px solid $border-related-results;

		h2 {
			margin: 5px 0 0;
			font-weight: 500;

			font: normal 25px/1.5 $font-brand-secondary;

			@include rem(font-size, 25px);
		}

		&:hover {
			color: $nav-link-hover;
		}

		@include until($tablet) {
			padding: 20px 8px;
		}

		.results-listing-item-img {
			display: none;
		}
	}
}
