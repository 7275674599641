.section__attorneys {
	padding: 100px 0;
	z-index: 3;
	transform: translate3d(0, 0, 0);

	&::after {
		border-top: 40px solid $med-gray;
	}

	.row {
		padding: 60px 0;
	}

	.img-rounded__link {
		color: $dark-green;
		transition: color 0.2s ease;

		&:after {
			background-color: $light-teal;
			width: 234px;
			height: 234px;
		}

		&:hover {
			color: $dark-green;
		}
	}

	.col-3 + .col-3 {
		padding-top: 40px;

		@media (min-width: 768px) {
			padding-top: 0;
		}
	}
}

.attorney__name {
	font-family: $headline-font, sans-serif;
	font-weight: 400;
	font-size: 30px;
	margin: 10px 0 10px 0;
}

.attorney__label {
	position: absolute;
	top: 60px;
	left: 50%;
	transform: translateX(-50%);

	font: 400 18px $headline-font, sans-serif;
	text-transform: uppercase;
	color: $white;
	opacity: 0;
	transition: opacity 0.2s ease;
	z-index: 5;

	.fa-chevron-right {
		margin-left: 8px;
	}

	.img-rounded__link:hover & {
		opacity: 1;
	}
}

.attorney__meta {
	font-family: $secondary-font;
	font-size: 20px;
	color: $dark-green;
	margin: 0;
}
