.global-footer {
	padding: 20px 0;
	color: $color-brand-white;
	font: normal 12px/1.2 $font-brand;

	a {
		color: rgba(255, 255, 255, 0.8);

		&:hover {
			color: $color-brand-white;
		}
	}

	&__circle-svg {
		margin: 0 60px 0 92px;
		height: 63px;
	}

	.inline-block {
		display: inline-block;
		vertical-align: middle;
		width: 49%;
	}

	.copyright {
		margin: 0;
		text-align: left;
		opacity: 1;
	}
	.nav-social {
		text-align: right;

		a {
			display: inline-block;
			text-transform: uppercase;
			border-left: 1px solid $white;
			font-size: 10px;
			padding-left: 7px;
			margin-left: 5px;
			opacity: 1;

			&:first-child,
			&.social {
				border-left: 0 none;
				padding-left: 0;
			}

			&:hover {
				opacity: 1;
				text-decoration: none;
			}

			&.social {
				font-size: 16px;
				margin-left: 10px;
			}
		}
	}
}

@media (max-width: 767px) {
	.global-footer {
		position: relative;

		&__circle-svg {
			margin: 20px auto 12px;
		}

		.inline-block {
			display: block;
			width: 100%;
			padding-bottom: 5px;
		}

		.nav-social,
		.copyright {
			text-align: center;
		}

		.page-wrap {
			padding-top: 20px;
			display: block;
		}

		.logo-dark {
			position: absolute;
			top: 25px;
			margin: 0 auto;
			left: 0;
			right: 0;
		}
	}
}
