.results-social-icons {
    margin-top: 30px;
}

.btn {
    &.results-social-icon {
        border: none;
        padding: 8px 10px;

        &:hover {
            color: white;
        }
    }
}

.iso-parent-box {
    background-color: #0d1d27;

    &.is-extended {
        cursor: default;
    }

    h2 {
        a {
            color: inherit;
        }
    }
}

.services-landing-nav-link {
    &:focus {
        outline: 0;
    }
}

.additional-text {
    display: none;
}

.global-tab-content {
    display: none;

    &.is-active {
        display: block;
    }
}

.related-services {
    .grid-row {
        &.one-column {
            &:before {
                display: none;
            }
        }
    }
}

.search-criteria-filter-item {
    &.is-active {
        text-decoration: none;
    }
}

.global-highlight-section {
    .results-listing-item {
        background: #fff;
    }
}

.results-listing-item {
    .abstract {
        padding-top: 0;
        margin-top: 30px;
    }
}

.next-article {
    &:not(.highlight-banner) {
        background-color: #386b62;
    }
}

.section__hero {
    background-image: none;
}

.logo-hero {
    background-image: none;
}

.grid-col-4 {
    width: 25%;
}

.person-snapshot-social-icon {
    background-color: transparent;
    font-size: 40px;
    width: 60px;
}

.people-related-services-list {
    > a {
        &:last-child {
            &:after {
                content: "";
                margin: 0;
            }
        }
    }
}

.no-style-list {
    list-style-type: none;
}

nobr {
    white-space: normal;
}

#results-container {
    margin-top: 0;
}

.results-listing-grid {
    .results-listing-item {
        width: 48%;
        margin: 1%;
    }
}

@media screen and (max-width: 599px) {
    .results-listing-grid .results-listing-item {
        width: 98%;
        max-width: 475px;
        margin: 0 auto;
    }
}

#filtered-news-container,
.recent-news-container {
    .results-listing-item {
        + .results-listing-item {
            margin-top: 1%;
        }
    }
}

.results-listing-grid {
    position: relative;
    overflow: hidden;
    display: block;
}

.global-site-nav {
    transition: top 0.3s ease-in-out;
}

.homepage {
    .global-site-nav {
        transition: none;
    }
}

.results-listing-item {
    &.full {
        border-width: 0 0 1px;
        padding: 20px 15px;
        width: 99%;

        .results-listing-item-img,
        .abstract {
            display: none;
            min-height: 0;
        }
    }
}

@media screen and (max-width: 499px) {
    .results-listing-item {
        width: 99%;
    }
}

span {
    &.field-required {
        display: none;
    }
}

div {
    &.form-validation-summary {
        display: none;
    }
}

span {
    &.field-error {
        font: italic 18px/1.1 georgia, serif;
        color: red;
    }
}
