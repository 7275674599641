button {
	padding: 0;
	border: 0 none;
	margin: 0;
	cursor: pointer;
	outline: 0;
	background-color: $color-brand-white;
	transition: color 0.2s ease-in, background-color 0.2s ease-in;

	&:focus {
		outline: 0;
	}
}

.btn-loadmore {
	display: inline-block;
	border: 1px solid $light-gray;
	padding: 8px 16px;
	letter-spacing: 1px;
	font-size: 11px;
	color: $light-gray;
	transition: background-color 0.2s ease;
	font-family: $tertiary-font;

	&:hover {
		background-color: $light-gray;
		color: $white;
		text-decoration: none;
	}
}

.btn {
	@extend %uc;

	display: inline-block;
	padding: 10px 16px;
	letter-spacing: 1px;
	font: 400 11px/1 $font-brand;
	border: 2px solid $light-gray;
}

.btn-load-more {
	margin: 40px auto 0;
	text-align: center;
	border-color: $black;
	color: $black;

	&:hover {
		background-color: $black;
		color: $color-brand-white;
	}
}

.btn-transparent {
	margin: 16px auto 0;
	text-align: center;
	border-color: $color-brand-white;
	color: $color-brand-white;

	&:hover {
		background-color: $color-brand-white;
		color: $black;
	}
}

.btn-text {
	padding: 0;
	border: 0 none;
	color: $black;
	font-size: 12px;

	&:hover {
		color: $nav-link-hover;
	}
}

.btn-clear {
	background-color: $bg-clear-button;
	color: $color-brand-white;
	border-color: transparent;
	padding: 8px;

	&:hover {
		background-color: $black;
	}
}

.btn-inverse {
	color: $color-brand-white;

	&:hover {
		color: $color-accent-grey;
	}
}

.custom-icon {
	display: inline-block;
	margin-top: -7px;
	transition: color 0.25s ease-in;

	&:before {
		content: "+";
		display: inline-block;
		font: 700 26px/1 $font-primary;
		width: 20px;
	}
}
