.list-unstyled {
	padding: 0;
	margin: 0;
	list-style: none;
}

.list-georgia {
	font-family: Georgia;
}

.list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.list-results {
	@extend %clearChildren;

	margin-top: 20px;

	> li {
		padding: 25px 8px;
		border-bottom: 1px solid $border-related-results;
		font-size: 14px;
		font-style: italic;

		@include until($tablet) {
			padding: 20px 8px;
		}
	}

	.list-result-credentials {
		font-style: normal;
	}
	a.list-results-link {
		&:hover {
			color: $nav-link-hover;
		}
	}
	.list-results-link {
		@extend .news-titles;

		color: $black;
		h3 {
			margin: 5px 0 0;
			font-weight: 500;
		}
	}
}

.list-result-credentials {
	float: left;
	width: 45%;

	p {
		margin: 0;

		& + p {
			margin-top: 15px;
		}
	}

	&:nth-child(2n + 1) {
		clear: left;
		margin-right: 5%;
	}

	@include until($tablet-alt) {
		width: 100%;

		&:nth-child(2n + 1) {
			margin-right: 0;
		}
	}
}

.list-results-title {
	@extend .news-titles;
	margin: 0 0 15px;

	color: $black;
}

.list-results-content {
	@include rem(font-size, 18px);
}

.list-commas {
	@extend .list;

	> li {
		display: inline-block;
		margin-right: 3px;

		&:after {
			content: ",";
		}

		&:last-child {
			&:after {
				content: "";
			}
		}
	}
}
