.people-results-content {
  display: none;
}

.person {
  @extend %clearChildren;

  padding: 25px 0;

  .people-results-list & {
    border-bottom: 1px solid $border-related-content;
  }
}

.people-results-list {
  a {
    color: $color-brand-brown;
  }

  .person-snapshot-title {
    font-size: 1em;
  }
}

.person-img {
  float: left;
  width: 200px;
  margin-right: 5%;

  @include until($tablet-alt) {
    width: 150px;
  }

  @include until($phone-landscape) {
    width: 30%;
  }

  > img {
    max-width: 100%;
  }
}

.person-title {
  @extend .main-listing-titles;
  margin: 0 0 2px;

  > a {
    color: $black;
  }
}

.person-info {
  display: table-cell;
  color: $color-brand-brown;

  p {
    @include rem(font-size, 18px);

    line-height: 1.2;
    margin-top: 3px;
  }

  .person-email {
    padding-top: 15px;
    margin-top: 15px;
    display: inline-block;

    &:before {
      display: block;
      position: absolute;
      content: "";
      width: 48px;
      height: 1px;
      background-color: $color-brand-brown;
      margin-top: -15px;
    }
  }
}
