// OLD GRID STYLES do not use, use grid new styles
.row {
	overflow: hidden;
}

.col-2,
.col-3,
.col-6 {
	@media (min-width: 768px) {
		float: left;
	}
}

.col-2 {
	@media (min-width: 768px) {
		width: 50%;
	}
}
.col-3 {
	@media (min-width: 768px) {
		width: 33%;
	}
}
.col-6 {
	@media (min-width: 768px) {
		width: 66%;
	}
}

//grid new

.grid-row {
	@extend %clearChildren;
}

[class*="grid-col"] {
	float: left;

	@include until($phone-wide) {
		float: none;
	}
}

.grid-col-3 {
	width: 33.33333%;

	@include until($tablet) {
		width: 50%;
	}

	@include until($phone-wide) {
		width: 100%;
	}
}

.grid-col-6 {
	width: 66.666667%;

	@include until($tablet) {
		width: 50%;
	}

	@include until($phone-wide) {
		width: 100%;
	}
}

.grid-col-halfs {
	width: 50%;

	@include until($phablet) {
		width: 100%;
	}

	.grid-row-padded & {
		width: 49%;

		&:nth-child(2n + 1) {
			margin-right: 2%;
			clear: left;
		}

		@include until($tablet-alt) {
			width: 100%;

			&:nth-child(2n + 1) {
				margin-right: 0;
			}
		}
	}
}

.grid-col-40 {
	width: 40%;
}

.grid-col-60 {
	width: 60%;
}
