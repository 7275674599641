.rich-text-fancy {
	p:first-child {
		&:first-letter {
			display: inline-block;
			font-size: 85px;
			line-height: 0.8;
			font-family: $font-headline;
			color: $black;
			float: left;
			margin: -13px 5px 0 0;

			@include until($tablet) {
				margin-top: -16px;
			}
		}
	}
}

.fancy-letter {
	display: inline-block;
	font-size: 85px;
	line-height: 1;
	font-family: $font-headline;
	float: left;
	margin: -25px 5px 0 0;
}

.spacing-top {
	margin-top: 30px;
}

.rich-text {
	h1 {
		font: normal 35px/1.2 $font-brand-secondary;

		@include rem(font-size, 35px);

		color: $color-accent-green;
	}
}
