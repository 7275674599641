.pull-left {
	float: left;
}
.pull-right {
	float: right;
}

.pull-left,
.pull-right {
	@extend %clearChildren;
}
.inner-padding {
	padding: 40px;
}

%clearChildren {
	&:after {
		display: table;
		content: "";
		clear: both;
	}
}
img {
	border: 0 none;
}
.img-responsive {
	max-width: 100%;
	width: 100%;
}
.img-rounded {
	border-radius: 50%;
}

.img-rounded__link {
	position: relative;
	display: inline-block;

	&:after {
		@extend .img-rounded;

		position: absolute;
		content: "";
		display: block;
		top: 10px; //top: 0;
		left: 9px; //left: 0;

		opacity: 0;
		transition: opacity 0.3s ease;
	}

	&:hover {
		text-decoration: none;

		&:after {
			opacity: 0.75;
		}
	}
}

.is-fixed {
	position: fixed;
}

.is-unfixed {
	position: absolute !important;
}

.is-disabled {
	visibility: hidden;
	opacity: 0;
}

.centered {
	text-align: center;
}

.list-unstyled {
	padding: 0;
	list-style: none;
}
%arrow {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	z-index: 1;
}

.arrow-up,
%arrow-up {
	&::after {
		@extend %arrow;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		border-left: 40px solid transparent;
		border-right: 40px solid transparent;
		border-bottom: 30px solid $color-brand-white;
	}
}

.arrow-down,
%arrow-down {
	&::after {
		@extend %arrow;
		bottom: -30px;
		left: 0;
		right: 0;
		margin: 0 auto;
		border-left: 40px solid transparent;
		border-right: 40px solid transparent;
		border-top: 40px solid $color-brand-white;
	}
}

%arrow-left {
	&::after {
		@extend %arrow;
		left: 0;
		border-top: 30px solid transparent;
		border-bottom: 30px solid transparent;
		border-left: 30px solid $color-brand-white;
	}
}

%arrow-right {
	&::after {
		@extend %arrow;
		right: 0;
		border-top: 30px solid transparent;
		border-bottom: 30px solid transparent;
		border-right: 30px solid $color-brand-white;
	}
}

.arrow-wrapper-left,
.arrow-wrapper-right {
	position: relative;
	&::after {
		transition: border-color 0.2s ease;
		top: 40%;
	}
}

.arrow-wrapper-bottom {
	position: absolute;
}

.arrow-wrapper-left {
	@extend %arrow-left;
}
.arrow-wrapper-right {
	@extend %arrow-right;
}

.arrow-wrapper-bottom {
	@extend %arrow-down;
}

@include until($tablet) {
	.mobile-only {
		display: block !important;
	}
}

@include at-least($tablet) {
	.mobile-only {
		display: none;
	}
}

@include until($tablet) {
	.mobile-exclude {
		display: none;
	}
}

@include at-least($tablet) {
	.mobile-exclude {
		display: inline-block;
	}
}

//Extends
%uc {
	text-transform: uppercase;
}

%inline-block {
	display: inline-block;
}
