.global-page-title {
	background-color: #141416;
	color: $color-brand-white;
	position: relative;
	overflow: hidden;
	padding: 30px 0 55px;
	margin-top: 25px;
	background-size: cover;

	@include until($tablet) {
		height: auto;
		padding: 25px 0 50px;
	}

	.page-wrap {
		max-width: 840px;
		height: 100%;
	}

	h1 {
		margin: 0;
		position: relative;

		font: 400 36px/1.4 $font-brand;

		@include rem(font-size, 36px);

		@include until($tablet) {
			font-size: 30px;
			font-size: 1.666667rem;
		}

		@include until($phablet) {
			font-size: 25px;
			font-size: 1.5625rem;
		}
	}

	.arrow {
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 53px;
		text-align: center;
		overflow: hidden;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 50%;
			height: 26px;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: $color-brand-white;
			margin-left: -27px;
		}

		&:after {
			left: auto;
			right: 0;

			margin-left: 0px;
			margin-right: -27px;
		}
	}
}

.down-arrow {
	display: inline-block;
	width: 54px;
	height: 54px;
	position: relative;

	&:before,
	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		bottom: 0;
		border-bottom: 27px solid $color-brand-white;
	}

	&:before {
		border-right: 28px solid transparent;
		left: 0;
	}

	&:after {
		border-left: 28px solid transparent;
		right: 0;
	}

	@include until($phablet) {
		width: 54px;
		height: 54px;

		&:before,
		&:after {
			border-bottom-width: 27px;
		}

		&:before {
			border-right-width: 28px;
			left: 0;
		}

		&:after {
			border-left-width: 28px;
		}
	}
}

.global-page-title-large {
	h1 {
		color: $color-brand-white;
		font: normal 70px/1.1 $font-headline;
		text-align: center;

		@include rem(font-size, 42px);

		@include until($tablet) {
			font-size: 55px;
			font-size: 3.05555rem;
		}

		@include until($phablet) {
			font-size: 40px;
			font-size: 2.5rem;
		}
	}
}

.global-page-title-grey {
	h1 {
		color: $color-brand-white;
	}

	.down-arrow {
		&:before,
		&:after {
			border-bottom-color: $color-accent-grey;
		}
	}

	.arrow {
		&:before,
		&:after {
			background-color: $color-accent-grey;
		}
	}
}
