.header-global {
	height: 60px;
	background-color: $bg-light-gray;
	border-bottom: 1px solid $header-border;
	box-shadow: 0px -2px 2px 0px $box-shadow-color;
	z-index: 10;
	text-align: center;
	margin-bottom: 0;

	// Solves repaint issues
	-webkit-transform: translate3d(0, 0, 0);

	.page-container {
		padding: 20px 0;
	}

	position: absolute;
	top: 520px;
	width: 100%;

	&.is-fixed {
		position: fixed;
		top: 0;
		transform: translateY(0);
		transition: transform 0.3s ease-in-out;

		.js-global-search-active & {
			transform: translateY(90px);
		}
	}
}

.logo-light {
	opacity: 0;
	transition: opacity 0.3s ease;

	&.is-animating {
		opacity: 1;
	}
}

.nav__main {
	font-size: 11px;
	font-weight: bold;

	@media (max-width: 768px) {
		font-size: 20px;
		font-weight: 100;
	}

	&:first-child {
		font-size: 14px;

		@media (max-width: 767px) {
			font-size: 34px;
			padding-bottom: 5px;
			letter-spacing: 1px;
		}
	}

	a {
		color: $main-nav-color;
		opacity: 0.6;
		display: inline-block;
		margin-right: 15px;
		font-family: $tertiary-font;

		&:hover {
			opacity: 1;
			text-decoration: none;
		}

		&:last-child {
			margin-right: 0;
		}

		&.is-active {
			opacity: 1;
		}

		.fa {
			color: $light-teal;
			font-size: 15px;

			.js-global-search-active &:before {
				content: "\f00d";
			}
		}
	}
}

// @media (max-width: 767px) {
// 	.header-global {
// 		position: absolute;
// 		top: 0;
// 		text-align: left;
// 		height: auto;
// 		padding-top: 60px;
// 		max-height: 60px;
// 		overflow: hidden;

// 		transition: max-height .4s ease-in-out;

// 		&.is-fixed {
// 			position: absolute;

// 		}

// 		&.is-active-mobilenav {
// 			max-height: 500px;
// 		}

// 		.logo-light {
// 			opacity: 1;
// 			position: absolute;
// 			top: 10px;
// 			left: 20px;
// 		}

// 		.page-container {
// 			padding: 0 20px;
// 		}
// 	}

// 	.nav__main {
// 		float: none;

// 		> a {
// 			display: block;
// 			margin-top: 15px;
// 		}

// 		.fa {
// 			display: none;
// 		}
// 	}
// }
