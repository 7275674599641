// input {
// 	font-size: 15px;
// 	padding: 24px 50px 24px 20px;
// 	background: $white;
// 	font-style: italic;
// 	border: 1px solid darken($med-gray, 10%);
// 	width: 100%;
// 	font-family: $secondary-font;
// }

.input-wrapper-search {
	margin: 0 auto;
	position: relative;

	i {
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		font-size: 25px;
		color: $light-teal;

		&:hover {
			color: $header-border;
		}
	}
}

.input-attorney {
	width: 470px;

	@media (max-width: 767px) {
		width: 100%;
	}
}

.scfForm {
	margin-top: 40px;
	input {
		border: solid 1px #ddd;
	}
	.field-content {
		margin-bottom: 20px;
	}
}
